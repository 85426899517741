import axios from "axios/index";

const GetUserAction = user => ({
    type: 'GET_USER',
    payload: user
});

// const clearUserAction = () => ({
//     type: 'CLEAR_USER'
// });

const login = ({userCredentials, setLoading, history}) => dispatch => {
    axios.post('/api/v1/login', userCredentials, { headers: {'Content-Type': 'application/json'}})
        .then(function (response) {
            if (response.data.status === 200) {
                localStorage.setItem('token', response.data.token);
                history.push('/');
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
            setLoading(false);
        });
};

const initUser = () => dispatch => {

    // const config = {
    //     headers: {
    //         Authorization: localStorage.getItem('token')
    //     }
    // };

    // Original user init
    // axios.get('/api/v1/init', config)
    //     .then(function (response) {
    //         dispatch(GetUserAction(response.data));
    //         props.history.push('/')
    //     })
    //     .catch(function (error) {
    //         props.history.push('/login')
    //     })
    //     .finally(function () {
    //     });

    // Fake user init
    axios.get('http://www.json-generator.com/api/json/get/bUXVXPQRbC?indent=2')
        .then(function (response) {
            dispatch(GetUserAction(response.data));
        })
        .catch(function (error) {
        })
        .finally(function () {
        });
};

// const logout = () => dispatch => {
//     dispatch(clearUserAction());
//     localStorage.clear();
// };

export default {
    login,
    initUser
};