import React from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from "axios/index";
import config from "../../config";

const UserAccount = (props) => {

    const firstName = props.userData.hasOwnProperty('firstName') ? props.userData.firstName : '';
    const lastName  = props.userData.hasOwnProperty('lastName')  ? props.userData.lastName  : '';
    const email     = props.userData.hasOwnProperty('email')     ? props.userData.email     : '';
    const password  = props.userData.hasOwnProperty('password')  ? props.userData.password  : '';

    const isUserExist = (email) => {
        return axios.post(config.getUrl() + '/api/v1/check/user/account', email)
            .then(function (response) {

                if (response.data.response) {
                    document.querySelectorAll('[type="email"]')[0].parentElement.classList.remove("has-error");
                }

                return !response.data.response;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    return (
        <Form key={1} onSubmit={props.form.userAccount.handleSubmitUserAccount(props.form.userAccount.onSubmitUserAccount)}>
            <Form.Group
                controlId="formName"
                className={
                    props.form.userAccount.errorsUserAccount.firstName
                        ? "has-error float-labels" : firstName !== "" ? "has-success float-labels" : "float-labels"
                }
            >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    onChange={(event) => props.onInputchange(event)}
                    className="custom-input"
                    name="firstName"
                    type="text"
                    defaultValue={firstName}
                    placeholder="First Name"
                    ref={props.form.userAccount.registerUserAccount({ required: true })}
                />
                {props.form.userAccount.errorsUserAccount.firstName && <Form.Text className="text-danger">This field is required!</Form.Text>}
            </Form.Group>
            <Form.Group
                controlId="formLastName"
                className={
                    props.form.userAccount.errorsUserAccount.lastName
                        ? "has-error float-labels" : lastName !== "" ? "has-success float-labels" : "float-labels"
                }
            >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    onChange={(event) => props.onInputchange(event)}
                    className="custom-input"
                    name="lastName"
                    type="text"
                    defaultValue={lastName}
                    placeholder="Last Name"
                    ref={props.form.userAccount.registerUserAccount({ required: true })}
                />
                {props.form.userAccount.errorsUserAccount.lastName && <Form.Text className="text-danger">This field is required!</Form.Text>}
            </Form.Group>
            <Form.Group
                controlId="formEmail"
                className={
                    props.form.userAccount.errorsUserAccount.email
                        ? "has-error float-labels" : email !== "" ? "has-success float-labels" : "float-labels"
                }
            >
                <Form.Label>Email</Form.Label>
                <Form.Control
                    onChange={(event) => props.onInputchange(event)}
                    className="custom-input"
                    name="email"
                    type="email"
                    defaultValue={email}
                    placeholder="Email"
                    ref={props.form.userAccount.registerUserAccount({
                        required: true,
                        validate: isUserExist,
                        // pattern: {
                        //     value: /^[A-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        //     message: "Enter a valid e-mail address (not allowed gmail, hotmail and yahoo)",
                        // }
                    })}
                />
                {props.form.userAccount.errorsUserAccount.email &&
                <Form.Text className="text-danger">
                    {props.form.userAccount.errorsUserAccount.email.message !== '' ?
                        props.form.userAccount.errorsUserAccount.email.message : props.form.userAccount.errorsUserAccount.email.type === "validate" ? 'Email already exist!' : 'This field is required!'}
                </Form.Text>
                }
            </Form.Group>
            <Form.Group
                controlId="formPassword"
                className={
                    props.form.userAccount.errorsUserAccount.password
                        ? "has-error float-labels" : password !== "" ? "has-success float-labels" : "float-labels"
                }
            >
                <Form.Label>Password</Form.Label>
                <Form.Control
                    onChange={(event) => props.onInputchange(event)}
                    className="custom-input"
                    name="password"
                    type="password"
                    defaultValue={password}
                    placeholder="Password"
                    ref={props.form.userAccount.registerUserAccount({ required: true })}
                />
                {props.form.userAccount.errorsUserAccount.password && <Form.Text className="text-danger">This field is required!</Form.Text>}
            </Form.Group>

            <div className="text-right">
                <Button className="btn-submit btn-block" type="submit">Save and continue</Button>
            </div>
        </Form>
    );
};

export default UserAccount;