const config = {
    borrowerProgressBarConfig() {
        return [
            {step: 'User Account'},
            {step: 'Company Information'},
            {step: 'KYC'},
            {step: 'Email Verification'}
        ];
    },
    infoSidebarMenu() {
        return [
            {
                'label': 'Invite a friend',
                'url'  : 'invite-friend',
                'icon' : 'faUserPlus'
            },
            {
                'label': 'Help Center',
                'url'  : 'help-center',
                'icon' : 'faQuestionCircle'
            }
        ];
    },
    borrowerMenu() {
        return [
            {
                'label': 'Dashboard',
                'url'  : '',
                'icon': 'faChartLine'
            },
            {
                'label': 'Add Invoice',
                'url'  : 'borrower/invoice/add',
                'icon': 'faFileImport'
            },
            {
                'label': 'My Investments',
                'url'  : 'borrower/investments',
                'icon': 'faFolderOpen'
            },
            {
                'label': 'Transaction',
                'url'  : 'borrower/transaction',
                'icon': 'faCoins'
            },
            {
                'label': 'Help Center',
                'url'  : 'help-center',
                'icon' : 'faQuestionCircle'
            }
        ];
    },
    borrowerVerificationMenu() {
        return [
            {
                'label': 'Verification',
                'url'  : 'borrower/verification',
                'icon': 'faUserSlash'
            },
            {
                'label': 'Help Center',
                'url'  : 'help-center',
                'icon' : 'faQuestionCircle'
            }
        ];
    },
    client() {
        return {
            client_id: 'b2SgjQEfxzenqgjHXW837RhCudCsKq',
            client_secret: 'bhvc8Pvf6JqBdWf9ZGCSMkBW4xp2UQ3mQard8KLY'
        };
    },
    getUrl() {
        return process.env.NODE_ENV === "development" ? "http://floatlending.local" : "https://staging.floatlending.com";
    }
};

export default config;